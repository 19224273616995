<template>
  <div>
    <div class="columns" style="overflow: hidden;" :style="gridStyle">
      <div class="col-8 box-parent" @click="clear">
        <div class="builder-box" style="padding:0 20px">
          <div class="inner">
            <!-- Header -->
            <img :src="headerImg">
            <img v-if="device==='mobile'" style="margin-top:-8px"
                 src="https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/lp_templates/BlockGnb.png">
            <template v-for="(block,idx) in computedPageBlocks">
              <img :src="block.preview" class="block-preview unselect" :key="'page-block-'+idx"
                   :class="{'selected': block.idx === selectedBlock.idx}"
                   @click.stop.prevent="clickPageBlock(block)">
            </template>
            <!-- Footer -->
            <img :src="footerImg">
          </div>
        </div>
      </div>
      <div class="col-4 box-parent">
        <div class="builder-box position-relative">
          <div class="block-title" v-if="blockName!==''">{{ blockName }} 템플릿 선택</div>
          <div class="inner" style="padding-top:60px">
            <template v-for="block in blocks">
              <img :src="block.preview" class="block-preview unselect" :key="'block-'+block.id"
                   @click="clickBlock(block)">
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UserAPIMixin from "../../mixins/UserAPIMixin";

  export default {
    name: "BlockBuilder",
    props: {
      device: {
        type: String
      },
      page: {
        type: Object
      },
      service: {
        type: Object
      }
    },
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.getData();
    },
    data() {
      return {
        id: 1,
        blocks: [],
        selectedBlock: {id:0},
        url: '',
        blockName: ''
      }
    },
    methods: {
      clear() {
        this.selectedBlock = {id:0};
        this.blockName = '';
        this.blocks = [];
      },
      clickPageBlock(block) {
        this.selectedBlock = block;
        this.getBlocks();
      },
      getData() {
        if((this.device === 'pc' && this.page.pageBlocksPc.length > 0) ||
          (this.device === 'mobile' && this.page.pageBlocks.length > 0)) {

          document.getElementsByClassName('inner')[0].scrollTop = 0;
          return;
        }
        let name = this.page.name;
        this.request.user.get(`ui/super_builder/page_blocks/${name}`).then(res=>{
          this.page.pageBlocks = res.data.mobile.filter(item => {
            return ['BlockGnb'].indexOf(item.name) === -1;
          });
          for(let i=0;i<this.page.pageBlocks.length;i++) {
            this.page.pageBlocks[i].idx = i;
          }

          this.page.pageBlocksPc = res.data.pc;
          for(let i=0;i<this.page.pageBlocksPc.length;i++) {
            this.page.pageBlocksPc[i].idx = i;
          }

          this.$nextTick(()=>{
            if(document.getElementsByClassName('inner').length>0)
              document.getElementsByClassName('inner')[0].scrollTop = 0;
          });
        });
      },
      getBlocks() {
        this.url = `ui/super_builder/blocks/${this.selectedBlock.name}?page_length=100`;
        let conv = this.service.conversions.map(item=>{ return item.params.filter(param=>{ return param.name === 'key'})[0].value}).toString();
        let plugin = this.service.plugins.map(item=>{ return item.params.filter(param=>{ return param.name === 'key'})[0].value}).toString();
        this.url += `&conversion=${conv}&plugin=${plugin}`;
        this.request.user.get(this.url).then(res=>{

          // 현재 선택한 블럭 외, 다른 동일한 이름의 블럭이 이미 추가되어 있는 경우 목록에서 제거
          // 중복 추가 막기 위함
          let pageBlocks = this.device === 'pc' ? this.page.pageBlocksPc : this.page.pageBlocks;
          pageBlocks = pageBlocks.filter(block => {
            return block.name === this.selectedBlock.name && block.template_name !== this.selectedBlock.template_name
          }).map(block => { return block.template_name });

          this.blocks = res.data.filter(data => { return pageBlocks.indexOf(data.template_name) === -1 });
          this.blockName  = this.blocks[0].kor_name;
        });
      },
      clickBlock(block) {
        this.selectedBlock.preview = block.preview;
        this.selectedBlock.template_name = block.template_name;
        this.selectedBlock.id = block.id;
      }
    },
    watch: {
      page() {
        this.clear();
        this.getData();
      },
      device() {
        this.clear();
        this.getData();
      }
    },
    computed: {
      computedPageBlocks() {
        return this.device === 'pc' ? this.page.pageBlocksPc : this.page.pageBlocks;
      },
      gridStyle() {
        return this.device === 'pc' ? {width: '100%'} : {width:'694px', margin: 'auto'};
      },
      headerImg() {
        return this.device === 'pc' ?
          'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/lp_templates/BlockHeaderPc.png' :
          'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/lp_templates/BlockHeader.png';
      },
      footerImg() {
        return this.device === 'pc' ?
          'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/lp_templates/t1_FooterPc.png' :
          'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/lp_templates/t1_Footer.png';
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .block-preview
    outline 2px solid transparent
    width 100%
  .block-preview:hover
    outline 2px solid #707070
  .selected
    outline 2px solid #FF6600 !important

  .box-parent
    overflow hidden
  .builder-box
    width 100%
    height calc(100vh - 318px)
    overflow-y hidden
    .inner
      height 100%
      border 1px solid #ddd
      overflow-y scroll
      padding 12px 12px 150px 12px
      line-height 2

  .block-title
    font-size 15px
    position absolute
    padding 12px
    width calc(100% - 22px)
    top 0
    left 0
    background #fafafa
    margin 1px

  img
    width 100%
</style>