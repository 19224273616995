<template>
  <div>
    <div style="padding-top:24px;width: 1000px;margin: 0 auto;">
      <div class="text-center" style="color:#837c7a">페이지에서 원하는 템플릿으로 교체해보세요</div>
      <div class="size-36 text-center">"{{ compName.korName }}" 페이지에서 원하는 디자인의<br><b>블럭 템플릿</b>으로 교체해보세요.</div>

      <div class="flex-center margin-top-16 margin-bottom-32">
        <div class="device unselect"
             :class="{'active': selectedDevice==='mobile'}"
             @click="selectedDevice='mobile'">
          <i class="material-icons">phone_iphone</i>
        </div>
        <div class="device unselect"
             :class="{'active': selectedDevice==='pc'}"
             @click="selectedDevice='pc'">
          <i class="material-icons">desktop_mac</i>
        </div>
      </div>
    </div>
    <block-builder v-if="page" :service="service" :device="selectedDevice" :page="page"></block-builder>
  </div>
</template>
<script>
  import UserAPIMixin from "../../../mixins/UserAPIMixin";
  import BlockBuilder from "../../component/BlockBuilder";


  export default {
    name: "SuperBuilderStep6",
    components: {
      BlockBuilder
    },
    mixins: [
      UserAPIMixin
    ],
    props: {
      step: {
        type: Number
      },
      service: {
        type: Object
      }
    },
    created() {
      this.getData();
    },
    data() {
      return {
        selectedDevice: 'mobile',
        page: undefined
      }
    },
    computed: {
      containerStyle() {
        return {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }
      },
      compName() {
        let name = '';
        let korName = '';
        switch(this.step) {
          case 5:
            name = 'Home';
            korName = '홈';
            break;
          case 6:
            name = 'Product';
            korName = '상품목록';
            break;
          case 7:
            name = 'ProductDetail';
            korName = '상품상세';
        }

        return {
          name: name,
          korName: korName
        };
      }
    },
    watch: {
      step() {
        this.getData();
      }
    },
    methods: {
      getData() {
        let page = this.service.pages.filter(item=>{ return item.name === this.compName.name });
        if(page.length===0) {
          this.page = {
            name: this.compName.name,
            pageBlocks: [],
            pageBlocksPc: []
          };
          this.service.pages.push(this.page);

        } else {
          this.page = page[0];
        }
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .device
    display flex
    background-color white
    border 1px solid $border2
    padding 8px
    border-radius 3px
    margin 0 5px
    font-size 28px

  .device.active
    border 1px solid $primary
    color $primary

</style>
